import { securedWrap } from '@mop/shared/utils/securedWrap';

export default function useMopRouter() {
  const route = useRoute();
  const router = useRouter();

  function getLocalePathByPage(pageNumber: number) {
    const query = { ...route.query };
    if (pageNumber === 1) {
      delete query.page;
    } else {
      query.page = pageNumber.toString();
    }

    return router.resolve({ query }).fullPath;
  }

  return securedWrap({
    getLocalePathByPage,
  });
}
